<template>
  <div
    id="case-index-container"
    fluid
    class="pl-3 pt-3"
  >
    <v-card
      class="py-6 px-6"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row justify-space-between">
        <div
          class="d-flex flex-row align-center pointer"
          @click="handleBackClick"
        >
          <v-icon color="black">
            mdi-arrow-left
          </v-icon>
          <h2 class="d-flex-inline justify-center align-center ml-2 ma-0 font-weight-bold">
            {{ name }}
          </h2>
          <div
            v-if="progressCalculation"
            class="d-flex flex-row align-center justify-space-between ml-6"
          >
            <v-menu
              open-on-hover
              bottom
              offset-y
              max-width="150px"
              content-class="view-executions-menu"
              z-index="100"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="progress-linear"
                  v-on="on"
                >
                  <div
                    class="progress-linear-bar"
                    :style="{backgroundColor: progressCalculation.percentage == 100 ? 'transparent' : '#EAECF0'}"
                  >
                    <div
                      class="partial passed"
                      :style="{width: `calc(${progressCalculation.progress.passed/testRunExecutions.length * 100}%)`}"
                    />
                    <div
                      class="partial incomplete"
                      :style="{width: `${progressCalculation.progress.incomplete/testRunExecutions.length * 100}%`}"
                    />
                    <div
                      class="partial failed"
                      :style="{width: `${progressCalculation.progress.failed/testRunExecutions.length * 100}%`}"
                    />
                  </div>
                  <span class="font-weight-regular ml-3">{{ progressCalculation.percentage != "NaN" ? progressCalculation.percentage : 0 }}%</span>
                </div>
              </template>

              <v-list>
                <v-list-item
                  v-for="(status, index) in progressCalculation.progress"
                  :key="index"
                  dense
                  height="35px"
                >
                  <v-list-item-title class="text-left">
                    <span
                      class="text-capitalize"
                      :style="{color: index == 'passed' ? '#66BB6A' : (index == 'incomplete' ? '#FFA726' : '#EF5350')}"
                    >{{ index }} {{ status }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="d-flex flex-row">
          <v-btn
            color="#F2F4F7"
            class="text-capitalize btn-theme rounded-lg mr-3"
            height="40px"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '92px'"
            :loading="isRerunLoading"
            :class="{ 'disabled-action': isRerunLoading }"
            depressed
            @click="duplicateTestRun"
          >
            {{ $t('testruns.rerun') }}
          </v-btn>
          <v-btn
            depressed
            color="primary"  
            height="40px"
            class="text-capitalize btn-theme rounded-lg"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '118px'"
            :loading="isCompleteRunLoading"
            :class="{ 'disabled-action': isCompleteRunLoading }"
            @click="completeTestRun"
          >
            {{ $t('testruns.complete') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <ExecutionManagement
      :show-create="false"
      :execution="selectedExecution"
      :executions="testRunExecutions"
      :assignees="assignees"
      :test-results="testResults"
      @bulkRemove="onBulkRemove"
      @getExecution="getExecution"
      @folder-select="getFolder"
      @updateExecution="updateTestExecution"
      @updateSelectedExecution="updateSelectedExecution"
      @updateResult="updateTestResult"
      @deleteResult="deleteTestResult"
      @deleteExecution="deleteExecution"
      @addResult="addTestResult"
      @moveItem="moveSelectedItem"
    />
  </div>
</template>

<script>
// import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import ExecutionManagement from '@/components/Execution/ExecutionManagement'
import { mapActions } from 'vuex';
import makeRunService from '@/services/api/run';
import makeExecutionService from '@/services/api/execution'
import makeResultService from '@/services/api/result'
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import dayjs from 'dayjs';

export default {
  components:{
    ExecutionManagement,
  },
  props: {
    CustomCases : Array,
    name: String
  },
  data() {
    return {
      actionMenu: false,
      selectedCases: [],
      testRunExecutions: [],
      folderId: null,
      selectedExecution: null,
      assignees: [],
      testResults: [],
      isRerunLoading: false,
      isCompleteRunLoading: false,
    };
  },
  computed:{
    progressCalculation(){
      const progress = {
        passed: 0,
        failed: 0,
        incomplete: 0
      }
      let inUsed = 0; 
      const executionsCount = this.testRunExecutions.length;
      this.testRunExecutions.map(item => { 
        progress[item.status.toLowerCase()] += 1;
        if(item.status.toLowerCase() !== 'new')
          inUsed += 1
      })

      const percentage = (inUsed / executionsCount * 100).toFixed(0);

      return {
        progress,
        percentage
      }
    },
  },
  methods: {
    ...mapActions({
        updateTestRuns:'run/updateTestRuns',
        uploadToServer: 'attachment/uploadToServer',
      }
    ),
    handleBackClick() {
      //this.showConfirmBackDialog = true;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    completeTestRun() {
      let payload = this.selectedCases.map(({externalId,customFields, source, uid, name }) => ({externalId: externalId,customFields, source, uid, name, completed: true}));
      this.isCompleteRunLoading = true;
      this.updateTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: null,
        payload: { runs: payload }
      })
        .then(() => {
          this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
        })
        .catch(error => {
          console.error("Failed to Update Test Run:", error);
        }).finally(() => {
          this.isCompleteRunLoading = false;
        });
    },
    async duplicateTestRun(){
      const runService = makeRunService(this.$api);

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.isRerunLoading = true;
      await runService.duplicateTestRun(handle, projectKey, runId).then(response => {
        showSuccessToast(this.$swal, this.$t('testruns.duplicate_testrun.success'))
        this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('testruns.duplicate_testrun.error'))
      }).finally(()=> {
        this.isRerunLoading = false;
      })
    },
    async getTestRunExecutions(handle, projectKey, runId){
      const runService = makeRunService(this.$api);
      await runService.getTestRunExecutions(
        handle,
        projectKey
        ,runId
      ).then(response => {
        this.testRunExecutions = response.data.executions;
        this.assignees = response.data.assignees;
      })
    },
    async getFolder(folderId){
      this.folderId = folderId;
    },
    async getExecution(executionId){
      await this.getExecutionResult(executionId)
      const selectedExecution = this.testRunExecutions.find(element => element.uid == executionId)
      const updatedAt = dayjs(selectedExecution.updatedAt).format('DD-MM-YYYY HH:mm:ss');
      this.selectedExecution = {
        ...selectedExecution,
        updatedAt
      }
    },
    updateSelectedExecution(selectedCases) {
      this.selectedCases = selectedCases;
    },
    async getExecutionResult(executionUid){
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      const projectKey = this.$route.params.key; 

      this.testResults = await resultService.getTestResults(handle, projectKey, executionUid).then(async response => {
        return response.data   
      }) 
    },
    onBulkRemove(payload){
      console.log("Delete from Test Run", payload)
    },
    async updateTestExecution(updatedData){
      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution?.uid;
      let payload = {};
      
      if(typeof(updatedData.value) == 'object')
        Object.assign(payload, {
          [updatedData.property] : updatedData.value.uid
        })
      else
        Object.assign(payload, {
          [updatedData.property] : updatedData.value
        })
      
      const findIndex = this.testRunExecutions.findIndex(element => element.uid == this.selectedExecution.uid);    

      await executionService.updateExecution(handle,projectKey,selectedExecution,payload).then(() => {
        this.testRunExecutions = this.testRunExecutions.map((item, index) => {
          if(index == findIndex){
            this.selectedExecution = {
              ...this.selectedExecution,
              [updatedData.property] : updatedData.value
            }
            return {
              ...this.selectedExecution,
            }
          }
          return {
            ...item
          }
        })
        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
    },
    async deleteExecution(id){
      const handle = this.$route.params.handle
      const projectKey = this.$route.params.key
      const executionService = makeExecutionService(this.$api);
      
      await executionService.deleteExecutions(handle, projectKey, id).then(() => {
        this.testRunExecutions = this.testRunExecutions.filter(element => element.uid !== id)
        showSuccessToast(this.$swal, this.$t('success.executionDeleted'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionDeletedFailed'))
      })
      
    },
    async moveSelectedItem(direction){
      let itemIndex = this.testRunExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(direction == 'next' && itemIndex < this.testRunExecutions.length-1)
        this.selectedExecution = this.testRunExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.testRunExecutions[itemIndex - 1]

      await this.getExecutionResult(this.selectedExecution.uid)
    },
    async addTestResult(data){
      const resultService = makeResultService(this.$api);
      const relatedTo = 'result';
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution.uid;

      const payload = {
        status: data.status,
        comment: data.comment
      };

      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        const mediaType = 'attachment'
        if(data.files.length)
         await Promise.all(data.files.map(async (file) => {
          await this.uploadToServer( {handle, mediaType ,relatedTo, relatedToUid:result.uid, file });
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResult(selectedExecution)
    },
    async updateTestResult(resultUid,data){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const relatedTo = 'result';
      const projectKey = this.$route.params.key;

      const payload = {
        status: data.status,
        comment: data.comment
      }
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then(async () => {
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        const mediaType = 'attachment'
        if(data.files.length)
          await Promise.all(data.files.map(async (file) => {
            await this.uploadToServer({handle, mediaType, relatedTo, relatedToUid: resultUid, file});
          })).then(() => {
            showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
          }).catch(() => {
            showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
          })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('success.testResultUpdated'))
      })
    },
    async deleteTestResult(resultUid){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    }
  },
  async created(){
      await this.getTestRunExecutions(
        this.$route.params.handle,
        this.$route.params.key,
        this.$route.params.id,
      )
  },
};
</script>
<style scoped>
.progress-linear{
  width: 100%;
  display: flex;
  align-items: center;
}
.progress-linear .progress-linear-bar{
    width: 120px !important;
    height: 8px;
    border-radius: 10px;
    display: flex;
}
.progress-linear .progress-linear-bar .partial{
  border-radius: 10px;
  height: 100%;
}
.progress-linear .progress-linear-bar .passed{
  background-color: #66BB6A;
  z-index: 10;
}
.progress-linear .progress-linear-bar .incomplete{
  background-color: #FFA726;
  position: relative;
  right: 5px;
  z-index: 9;
}
.progress-linear .progress-linear-bar .failed{
  background-color: #EF5350;
  position: relative;
  right: 10px;
  z-index: 8;
}
</style>
<style>
  .custom-progressbar{
    width: 200px;
  }
  .__attachment_progress{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 8px;
    flex-direction: column !important;
    z-index: 999;
  }
</style>
